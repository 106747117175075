import * as UPSELL_FROM from 'modules/Analytics/constants/upsellFrom';
import analytics from 'modules/Analytics';
import Item from './primitives/Item';
import Items from './primitives/Items';
import Link from './primitives/Link';
import UpgradeButton from './primitives/UpgradeButton';
import UPSELL from 'constants/upsellTracking';
import url from 'url';
import { addParams } from 'utils/url';
import { getNavList, NavLinks } from 'components/SideNav/helpers';
import { PureComponent } from 'react';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';

export type Connected = {
  customRadioEnabled: boolean;
  hasUpgradeBtn: boolean;
  internationalPlaylistRadioEnabled: boolean;
  navLinks: NavLinks;
  showPlaylists: boolean;
  upgrade: string;
};

export type Props = Connected & {
  closeNav: () => void;
  initialRender: boolean;
  translate: IGetTranslateFunctionResponse;
};

class NavItems extends PureComponent<Props> {
  static defaultProps = { upgrade: '/upgrade' };

  getNavItems() {
    const {
      internationalPlaylistRadioEnabled,
      navLinks,
      showPlaylists,
      customRadioEnabled,
      closeNav,
      initialRender,
      translate,
    } = this.props;

    return getNavList({
      customRadioEnabled,
      internationalPlaylistRadioEnabled,
      navLinks,
      pageNamePrefix: 'sidenav',
      showPlaylists: initialRender ? false : showPlaylists,
      translate,
    }).map(props => {
      const parsedUrl = url.parse(props.to ?? '');

      return {
        ...props,
        onClick: closeNav,
        target: parsedUrl.protocol ? '_blank' : null,
      };
    });
  }

  upsellFromAnalytics = () => {
    analytics.trackUpsellOpen!({
      destination: UPSELL_FROM.NEW_SCREEN,
      promotionSubscriptionTier: UPSELL_FROM.GENERAL,
      upsellFrom: UPSELL_FROM.SIDE_NAV_UPGRADE_BANNER,
      upsellType: UPSELL_FROM.BANNER,
      vendor: UPSELL_FROM.NATIVE,
    });
    return this.props.closeNav();
  };

  render() {
    const { hasUpgradeBtn, upgrade, closeNav, initialRender, translate } =
      this.props;
    const upgradeLink = hasUpgradeBtn
      ? addParams(upgrade, { upsellFrom: UPSELL.SIDE_NAV_UPGRADE_BUTTON.id })
      : '';

    return (
      <Items>
        {hasUpgradeBtn && !initialRender ? (
          <UpgradeButton
            data-test="upgrade-button"
            onClick={this.upsellFromAnalytics}
            to={upgradeLink}
          >
            {translate('Upgrade Now')}
          </UpgradeButton>
        ) : null}
        {this.getNavItems().map(props => {
          const { to, title, text } = props;

          return (
            <Item key={to}>
              <Link
                {...props}
                activeClassName="active"
                onClick={closeNav}
                title={title}
                to={to}
              >
                {text}
              </Link>
            </Item>
          );
        })}
      </Items>
    );
  }
}

export default NavItems;
