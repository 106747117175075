import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  direction: 'down' | 'up';
  show: boolean;
};

const Mask = styled('div')<Props>(({ direction, show, theme }) => ({
  backgroundImage:
    direction === 'up'
      ? 'linear-gradient(to top, rgba(42, 42, 42, 1), rgba(51, 51, 51, 0) 25%)'
      : 'linear-gradient(180deg, #2a2a2a, rgba(51, 51, 51, 0) 25%)',
  bottom: '11.8rem',
  borderTop:
    direction === 'down' ? `1px solid ${theme.colors.gray['300']}` : undefined,
  display: 'block',
  maxWidth: '32.26rem',
  opacity: show ? 1 : 0,
  pointerEvents: 'none',
  position: 'fixed',
  top: '5.5rem',
  transition: 'opacity 0.5s',
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    maxWidth: '27.5rem',
  },
}));

export default Mask;
